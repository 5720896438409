import emotionStyled from "@emotion/styled"
import { useMemo } from "react"
import { formatTime, processText } from "../utils/utils"
import { Box, Typography } from "@mui/material"
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'

const TabGames = ({ report }) => {
    const games = useMemo(() => {
        return [report.games[0]]
    }, [report])

    return (
        <>
            {games.map((game, index) => {
                return <Game key={game.universe_id} game={game} mostPlayed={index === 0} />
            })}
        </>
    )
}

const StyledGameCard = emotionStyled(Box)`
    background: #28154C;
    color: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 16px;
`

const Game = ({ game, mostPlayed }) => {
    const content = useMemo(() => processText(game.parents_need_to_know), [game])

    return (
        <Box display="grid" gridTemplateColumns="218px 1fr" marginBottom={8} gap={4}>
            <Box>
                <StyledGameCard>
                    <Typography variant="h3" textAlign="center">{game.name}</Typography>
                    {mostPlayed &&
                        <Typography variant="body2" fontWeight="600" textAlign="center" color="#A87BFF">Most played game</Typography>                    
                    }
                    <Box marginTop={2} gap={1} display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center" gap={0.5} color="#E6D9FF">
                            <AccessAlarmIcon />
                            <Typography variant="caption" flexGrow="1">Time played:</Typography>
                            <Typography variant="caption">{formatTime(game.time)}</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={0.5} color="#E6D9FF">
                            <SportsEsportsIcon />
                            <Typography variant="caption" flexGrow="1">Platform:</Typography>
                            <Typography variant="caption">Roblox</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={0.5} color="#E6D9FF">
                            <PeopleOutlineIcon />
                            <Typography variant="caption" flexGrow="1">Interaction:</Typography>
                            <Typography variant="caption">Yes</Typography>
                        </Box>
                    </Box>
                </StyledGameCard>
            </Box>
            <Box>
                <Typography variant="h4">What parent's need to know 🤔</Typography>
                <Typography variant="body1" marginTop={2}>{content}</Typography>
                <Typography variant="h4" marginTop={3}>Potential risks ⚠️</Typography>
                <Box marginTop={3} display="grid" gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={2} >
                    {game.reviews.map(review => {
                        return <Review key={review.title} review={review} />
                    })}
                </Box>
            </Box>
        </Box>
    )
}


const BADGE_COLORS = {
    'LOW': '#72BA3780',
    'MEDIUM': '#EDB43E80',
    'HIGH': '#C63C3580',
}

const CustomBadge = emotionStyled(Box)`
    background: ${({ rate }) => BADGE_COLORS[rate]};
    padding: 3px 10px;
    border-radius: 4px;
`

const StyledReview = emotionStyled(Box)`
    background-color: #D0D5DD38;
    padding: 16px;
    border-radius: 8px;
`

const Review = ({ review }) => {
    const content = useMemo(() => processText(review.description), [review])

    return (
        <StyledReview>
            <Box display="flex" alignItems="center" marginBottom={2}>
                <Typography variant="body1" fontWeight="600" flexGrow="1">
                    {review.title}
                </Typography>
                <CustomBadge rate={review.rate}>
                    <Typography variant="caption" textTransform="capitalize">{review.rate}</Typography>
                </CustomBadge>
            </Box>
            <Box>
                {content}
            </Box>
        </StyledReview>
    )
}

export default TabGames