import { store } from "./store"


const getToken = () => {
    const credentials = store.getState().auth.credentials
    if (!credentials) return undefined
    return credentials.token
}

const post = (url, data) => {
    return fetch(process.env.REACT_APP_API_URL + url, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
}

const get = (url) => {
    return fetch(process.env.REACT_APP_API_URL + url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
}

export const apiLogin = ({ accessToken }) => {
    return post('/v1/auth/login', { accessToken, full: true })
}

export const apiGetKids = () => {
    return get('/v1/parent/kids')
}

export const apiGetRobloxData = ({ profile_id }) => {
    return get(`/v1/extensions/roblox/profile/${profile_id}/data`)
}

export const apiGetRobloxReports = ({ profile_id }) => {
    return get(`/v1/extensions/roblox/profile/${profile_id}/reports`)
}

export const apiCheckRobloxUsername = ({ username }) => {
    return get(`/v1/extensions/roblox/check/${username}`)
        .then(response => response.data.results)
}

export const apiGetDefaultAvatarConfiguration = ({ avatar_id }) => {
    return get(`/v1/avatars/configuration/${avatar_id}`)
}

export const apiCheckNicknameAvailability = ({ nickname }) => {
    return post(`/v1/parent/kids/nickname/availability`, { nickname })
        .then(response => response.data.available)
}

export const apiCreateKidProfile = ({ nickname, avatar, avatar_configuration, gender, birthday }) => {
    const data = { nickname, avatar, avatar_configuration, gender, birthday }
    return post('/v1/parent/kids', data)
        .then(response => response.data)
}

export const apiAddRobloxProfile = ({ profile_id, roblox_kid_id, roblox_name }) => {
    const data = { roblox_kid_id, roblox_name }
    return post(`/v1/extensions/roblox/profile/${profile_id}/setup`, data)
}
