import emotionStyled from "@emotion/styled"
import { Box, Button, IconButton, Typography } from "@mui/material"
import { processText } from "../utils/utils"
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useCallback, useMemo, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"

const StyledGradientBox = emotionStyled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 56px;
    border-radius: 8px;
    padding: 20px 26px;
    background: rgb(9,10,38);
    background: linear-gradient(90deg, rgba(9,10,38,1) 0%, rgba(100,65,126,1) 100%);
    color: #ffffff;
`

const TabInsights = ({ report }) => {
    const [conversationStartersCount, setConversationStartersCount] = useState(4)

    const conversationStarters = useMemo(() => {
        if (!report) return null
        return report.conversation_starters.slice(0, conversationStartersCount)
    }, [report, conversationStartersCount])

    const handleShowOthers = useCallback(() => {
        setConversationStartersCount((value) => value + 4)
    }, [])

    return (
        <>
            <StyledGradientBox>
                <Box>
                    <Typography variant="h2">
                        Key
                    </Typography>
                    <Typography variant="h2">
                        Takeaway ✨
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body2">
                        @{report.kid_name} has been playing more than last week. Their mood has been shifting from educational to programming games. There is a tendency in their gaming for multiplayer social games. We recommend keep an eye on the private chat or use Gabu's app for peer to peer communication.
                    </Typography>
                </Box>
            </StyledGradientBox>
            <Typography variant="h3" marginTop={3}>
                Conversation starters 🤗
            </Typography>
            <Typography variant="body1">
                Start the conversation with your kids with this ideas based on the games they play.
            </Typography>
            <Box marginTop={3} display="grid" gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={2} >
                {conversationStarters && conversationStarters.map((entry, index) => {
                    return <ConversationStarter key={index} data={entry} highlight={index === 0} />
                })}
            </Box>
            <Box marginTop={3}>
                <Button variant="gradient" onClick={handleShowOthers}>Show others</Button>
            </Box>
        </>
    )
}

const StyledConversationStarter = emotionStyled(Box)`
    background-color: ${({ $highlight }) => $highlight ? '#6D36DA' : '#D0D5DD38'};
    color: ${({ $highlight }) => $highlight ? '#ffffff' : 'inherit'};
    padding: 16px;
    border-radius: 8px;
`

const ConversationStarter = ({ data, highlight }) => {
    const content = useMemo(() => processText(data.description), [data])

    return (
        <StyledConversationStarter $highlight={highlight}>
            <Box display="flex" alignItems="center" marginBottom={2} >
                <Typography variant="body1" fontWeight="600" flexGrow="1">
                    {data.title}
                </Typography>
                <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    sx={{ mr: 2 }}
                >
                    <ThumbUpOffAltIcon />
                </IconButton>
                <CopyToClipboard text={data.description}>
                    <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        sx={{ mr: 2 }}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </CopyToClipboard>
            </Box>
            <Box>
                {content}
            </Box>
        </StyledConversationStarter>
    )
}

export default TabInsights