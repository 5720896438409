import MainBar from "./components/MainBar"
import theme from "./utils/theme"
import { Box, CssBaseline, ThemeProvider } from "@mui/material"
import KidList from "./components/KidList"
import emotionStyled from "@emotion/styled"
import MainContent from "./components/MainContent"
import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { apiLogin } from "./utils/api"
import { useDispatch } from "react-redux"
import { setCredentials } from "./reducers/auth"
import CircularProgress from '@mui/material/CircularProgress'

const MainContainer = emotionStyled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const StyledContainer = emotionStyled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  padding: 32px;
  height: 100%;
`

const App = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect()
    } else if (isAuthenticated) {
      getIdTokenClaims()
        .then(response => apiLogin({ accessToken: response.__raw }))
        .then(response => dispatch(setCredentials(response.data)))
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, getIdTokenClaims, dispatch])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainContainer>
        <MainBar />
        <StyledContainer>
          {isLoading &&
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
              <CircularProgress color="primary" />
            </Box>
          }
          {!isLoading && isAuthenticated &&
            <>
              <KidList />
              <MainContent />
            </>
          }
        </StyledContainer>
      </MainContainer>
    </ThemeProvider>
  )
}

export default App
