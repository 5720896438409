import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    credentials: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      state.credentials = action.payload
    },
  },
})

const authReducer = authSlice.reducer

export const { setCredentials } = authSlice.actions

export default authReducer