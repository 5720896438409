import { configureStore } from '@reduxjs/toolkit'
import kidReducer from '../reducers/kid'
import authReducer from '../reducers/auth'

export const store = configureStore({
  reducer: {
    kid: kidReducer,
    auth: authReducer,
  },
})
