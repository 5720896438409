import emotionStyled from "@emotion/styled"
import { Box, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useToken } from "../hooks/AuthHooks"
import { apiGetRobloxData, apiGetRobloxReports } from "../utils/api"
import { useKid } from "../hooks/KidHooks"
import TabInsights from "./TabInsights"
import TabGames from "./TabGames"
import TabTimePlayed from "./TabTimePlayerd"
import CircularProgress from '@mui/material/CircularProgress'
import { useAuth0 } from "@auth0/auth0-react"
import dayjs from "dayjs"

const StyledContainer = emotionStyled(Box)`
    width: 100%;
    max-width: 950px;
    border-radius: 18px;
    background-color: ${({ theme }) => theme.palette.background.paper};
    padding: 32px 56px 48px 56px;
`

const StyledImage = emotionStyled.img`
    height: 241px;
`

const weekSelectStyle = {
    "&.MuiInputBase-root::before": {
        border: "none",
    },
}

const MainContent = () => {
    const token = useToken()
    const kid = useKid()
    const { user } = useAuth0()
    const [robloxData, setRobloxData] = useState()
    const [selectedTab, setSelectedTab] = useState(0)
    const [report, setReport] = useState()
    const [reports, setReports] = useState()
    const [selectedReport, setSelectedReport] = useState('-')

    const handleTabChange = useCallback((_event, newValue) => {
        setSelectedTab(newValue)
    }, [])

    const handleSelectReport = useCallback((event) => {
        setSelectedReport(event.target.value)
    }, [])

    useEffect(() => {
        if (!token || !kid) return
        setRobloxData(undefined)
        apiGetRobloxData({ profile_id: kid.profile_id })
            .then((response) => {
                if (response.data?.roblox) setRobloxData(response.data?.roblox)
                else setRobloxData(false)
            })
            .catch((err) => console.error(err))
    }, [token, kid])

    useEffect(() => {
        if (!token || !kid) return
        apiGetRobloxReports({ profile_id: kid.profile_id })
            .then((response) => {
                setReports(response.data?.roblox)
            })
            .catch((err) => console.error(err))
    }, [kid, token])

    useEffect(() => {
        if (!robloxData) return
        const url = (selectedReport === '-') ? `/reports/${robloxData.last_report_id}.json` : `/reports/${selectedReport}.json`
        fetch(url, { mode: 'no-cors' })
            .then(response => {
                return response.json()
            })
            .then(report => setReport(report))
            .catch((err) => console.error(err))
    }, [robloxData, selectedReport])

    return (
        <StyledContainer>
            {robloxData === undefined &&
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
                    <CircularProgress color="primary" />
                </Box>
            }
            {robloxData === false &&
                <Box display="flex" gap={2} flexDirection="column" alignItems="center">
                    <StyledImage src='/images/guy_magnifying_glass.png' alt='' />
                    <Typography variant="h2">
                        {user && user.given_name.trim()}, we are preparing your report
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                        In order to give you great insights we are looking for information in Samkid's Roblox account.  We need at least 24 hours of play data in order to have relevant information to share. We will send you an email when your first report is ready!
                    </Typography>
                    <Typography variant="body1" textAlign="center">
                        In the meantime, you can download our app for kids where your children can communicate with their friends while playing online. It's like a SAFE Discord but built for kids!
                    </Typography>
                    <Box display="flex" gap={1} margin={2} flexDirection="row">
                        <img src='/images/google_play.png' alt='Google Play' />
                        <img src='/images/apple_store.png' alt='Apple Store' />
                    </Box>
                </Box>
            }
            {robloxData && report &&
                <Box>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h2">Roblox activity report</Typography>
                        <Select
                            variant="standard"
                            size="small"
                            sx={weekSelectStyle}
                            value={selectedReport}
                            onChange={handleSelectReport}
                        >
                            <MenuItem value="-">
                                <Typography variant="caption">Show: This Week</Typography>
                            </MenuItem>
                            {reports && reports.map((report) => {
                                return (
                                    <MenuItem value={report.report_id}>
                                        <Typography variant="caption">{dayjs(report.date_start).format('MMMM D')} to {dayjs(report.date_end).format('MMMM D')}</Typography>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Box>
                    <Box marginTop={3}>
                        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Insights" />
                            <Tab label="Games" />
                            <Tab label="Time played" />
                        </Tabs>
                        <CustomTabPanel value={selectedTab} index={0}>
                            <TabInsights report={report} />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={1}>
                            <TabGames report={report} />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={2}>
                            <TabTimePlayed report={report} />
                        </CustomTabPanel>
                    </Box>
                </Box>
            }
        </StyledContainer>
    )
}

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

export default MainContent