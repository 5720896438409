import emotionStyled from "@emotion/styled"
import { Box, Typography } from "@mui/material"
import { useMemo } from "react"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const StyledHoursPlayed = emotionStyled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    border-radius: 8px;
    padding: 40px;
    padding-left: 265px;
    background: rgb(9,10,38);
    background: linear-gradient(90deg, rgba(9,10,38,1) 0%, rgba(100,65,126,1) 100%);
    color: #ffffff;
    position: relative;
    min-height: 248px;
`

const StyledKidsRunning = emotionStyled.img`
    width: 285px;
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
`

const Separator = emotionStyled.div`
    display: flex;
    margin: 0px 24px;
    border-left: 1px solid #5529A8;
`

const ONE_DAY_SECONDS = 60 * 60 * 24

const TabTimePlayed = ({ report }) => {

    const timePerDay = useMemo(() => {
        if (!report.presence) return []
        const emptyReport = {}
        const startDate = new Date(report.start_date)
        for (let i = 0; i < 7; i++) {
            const day = startDate.getDay()
            emptyReport[day] = { day, time: 0 }
            startDate.setDate(startDate.getDate() + 1)
        }
        const result = report.presence.reduce((memo, entry) => {
            const date = new Date(entry.online_after)
            const day = date.getDay()
            memo[day].time += entry.time_online
            return memo
        }, emptyReport)
        return Object.values(result)
    }, [report])

    const [upwards, percent] = useMemo(() => {
        const diff = Math.round(report.total_time * 100 / report.previous_total_time) - 100
        if (diff < 0) return [false, -diff]
        else return [true, diff]
    }, [report])

    return (
        <StyledHoursPlayed>
            <StyledKidsRunning src="/images/kids_running.png" alt='' />
            <Typography variant="h2">Hours played 🎮</Typography>
            <Box display="flex">
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    <Typography variant="h2" fontSize={64}>{Math.round(report.total_time / 3600)}</Typography>
                    <Typography variant="caption" color="#AC82FF">Total hours at Roblox</Typography>
                </Box>
                <Separator />
                <Box display="flex" gap={3.5}>
                    {timePerDay.map((entry, index) => <Line key={entry.day} day={entry.day} time={entry.time} index={index} />)}
                </Box>
                <Box marginLeft={4}>
                    <Box display="flex" alignItems="center">
                        {upwards && <ArrowUpwardIcon sx={{ color: upwards ? '#39C463' : '#C63C35' }} />}
                        {!upwards && <ArrowDownwardIcon sx={{ color: upwards ? '#39C463' : '#C63C35' }} />}
                        <Typography variant="body2" fontWeight="600" color={upwards ? '#39C463' : '#C63C35'}>{percent}%</Typography>
                    </Box>
                    <Typography variant="caption" fontWeight="600" color="#A87BFF" lineHeight="100%">Than last week</Typography>
                </Box>
            </Box>
        </StyledHoursPlayed>
    )
}

const StyledLine = emotionStyled.div`
    display: flex;
    width: 5px;
    height: ${({ $time }) => Math.round($time * 195 / ONE_DAY_SECONDS) + 5}px;
    background: ${({ $color }) => $color};
    border-radius: 3px;
`

const DAY_COLORS = ['#562AA9', '#663EB1', '#9B82CC', '#B19DD7', '#C0B0DF', '#E4DDF2', '#FEFDFF']
const DAYS_OF_WEEK = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

const Line = ({ day, time, index }) => {
    const timeSummary = useMemo(() => {
        const minutes = time / 60
        if (time < 60) return `${time}m`
        const hours = Math.round(minutes / 60)
        return `${hours}h`
    }, [time])

    return (
        <Box key={day} display="flex" flexDirection="column" gap={1} justifyContent="flex-end" alignItems="center">
            <Typography variant="caption" fontWeight="600" color="#A87BFF">{timeSummary}</Typography>
            <StyledLine $time={time} $index={index} $color={DAY_COLORS[index]} />
            <Typography variant="caption" fontWeight="600" color="#A87BFF">{DAYS_OF_WEEK[day]}</Typography>
        </Box>
    )
}

export default TabTimePlayed