import { AppBar, Avatar, Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import NotificationsNone from '@mui/icons-material/NotificationsNone'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAuth0 } from "@auth0/auth0-react"
import { bindMenu, bindTrigger } from "material-ui-popup-state"
import LogoutIcon from '@mui/icons-material/Logout'
import { usePopupState } from "material-ui-popup-state/hooks"
import { useCallback } from "react"

const MainBar = () => {
    const { user, logout } = useAuth0()
    const menuPopupState = usePopupState({ variant: 'popover' })

    const handleLogout = useCallback(() => {
        menuPopupState.close()
        logout({ logoutParams: { returnTo: 'https://playgabu.com' }, openUrl: (url) => {
            window.location.href = url
        }})
    }, [menuPopupState, logout])

    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <img src="/logo_white.svg" alt='' />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                        <NotificationsNone />
                    </IconButton>
                    <Button
                        color="inherit"
                        endIcon={<ExpandMoreIcon />}
                        {...bindTrigger(menuPopupState)}
                    >
                        <Box mr={1} ><Avatar sx={{ width: 24, height: 24 }} src={user?.picture} /></Box>
                        {user?.name}
                    </Button>
                    <Menu {...bindMenu(menuPopupState)}>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon><LogoutIcon fontSize="small" /></ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default MainBar