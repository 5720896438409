import { createSlice } from '@reduxjs/toolkit'

const kidSlice = createSlice({
  name: 'kid',
  initialState: {
    kid: null,
    current_report: null,
  },
  reducers: {
    setKid: (state, action) => {
      state.kid = action.payload
    },
    setCurrentReport: (state, action) => {
      state.current_report = action.payload
    },
  },
})

const kidReducer = kidSlice.reducer

export const { setKid, setCurrentReport } = kidSlice.actions

export default kidReducer