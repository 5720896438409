import "@fontsource/nunito-sans/400.css"
import "@fontsource/nunito-sans/500.css"
import "@fontsource/nunito-sans/600.css"
import { createTheme } from "@mui/material"

const theme = createTheme({
    palette: {
        primary: {
            main: "#5529a8",
        },
        background: {
            default: "#f5f2fd",
            paper: "#ffffff",
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'gradient' },
                    style: {
                        background: "linear-gradient(90deg, rgba(9,10,38,1) 0%, rgba(100,65,126,1) 100%)",
                        color: '#ffffff',
                        padding: '6px 16px',
                        fontWeight: '500',
                    },
                },
            ],
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&': {
                        textTransform: 'none',
                        borderRadius: 24,
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&': {
                        marginLeft: 16,
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#5529A8',
                        color: "#ffffff",
                        fontWeight: '600',
                        borderTopLeftRadius: 9,
                        borderBottomLeftRadius: 9,
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#5529A8',
                        color: "#ffffff",
                        fontWeight: '600',
                    },
                },
            },
        },
    },
    typography: {
        h2: {
            fontSize: 24,
            fontWeight: 600,
        },
        h3: {
            fontSize: 20,
            fontWeight: 600,
        },
        h4: {
            fontSize: 18,
            fontWeight: 600,
        },
        body1: {
            fontSize: 16,
        },
        body2: {
            fontSize: 14,
        },
        button: {
            fontSize: 16,
            fontWeight: 500,
        },
        caption: {
            fontSize: 12,
        },
    },
})

export default theme