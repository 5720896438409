import { Avatar, Box, Button, FormControl, MenuItem, IconButton, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, Modal, Select, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import AddIcon from '@mui/icons-material/Add'
import emotionStyled from "@emotion/styled"
import { apiAddRobloxProfile, apiCheckNicknameAvailability, apiCheckRobloxUsername, apiCreateKidProfile, apiGetDefaultAvatarConfiguration, apiGetKids } from "../utils/api"
import { useToken } from "../hooks/AuthHooks"
import { getAvatarURI } from "../utils/utils"
import { useDispatch } from "react-redux"
import { setKid } from "../reducers/kid"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CircularProgress from '@mui/material/CircularProgress'
import dayjs from "dayjs"

const StyledContainer = emotionStyled(Box)`
    width: 100%;
    max-width: 360px;
    border-radius: 18px;
    background-color: ${({ theme }) => theme.palette.background.paper};
`

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'scroll',
    maxHeight: '90%',
    width: 520,
    backgroundColor: "#ffffff",
    borderRadius: '6px',
    boxShadow: 24,
    padding: '64px 100px',
}

const avatarsStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#ffffff",
    borderRadius: '6px',
    boxShadow: 24,
    padding: '64px 100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 32,
}

const editAvatarStyle = {
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderColor: '#C2C8D0',
    '&:hover': {
        borderColor: '#000000',
        backgroundColor: '#ffffff',
    }
}

const MINIMUM_ROBLOX_NAME_LENGTH = 6

const KidList = () => {
    const [selectedId, setSelectedId] = useState()
    const [kids, setKids] = useState()
    const [open, setOpen] = useState(false)
    const [avatarsOpen, setAvatarsOpen] = useState(false)
    const [avatar, setAvatar] = useState(() => `default-avatar-${Math.floor(Math.random() * 1000)}`)
    const [avatarConfiguration, setAvatarConfiguration] = useState()
    const [nickname, setNickname] = useState('')
    const [nicknameAvailable, setNicknameAvailable] = useState()
    const [birthday, setBirthday] = useState(dayjs(new Date()))
    const [gender, setGender] = useState('female')
    const [robloxName, setRobloxName] = useState('')
    const [foundRobloxAccount, setFoundRobloxAccount] = useState(null)
    const [namesLoading, setNamesLoading] = useState(false)
    const validForm = nicknameAvailable && (foundRobloxAccount || robloxName.length === 0)

    const token = useToken()
    const dispatch = useDispatch()

    const handleNicknameChange = useCallback((event) => {
        setNickname(event.target.value)
    }, [])

    const handleRobloxNameChange = useCallback((event) => {
        setRobloxName(event.target.value)
    }, [])

    const handleBirthdayChange = useCallback((event) => {
        console.log(event)
        setBirthday(event)
    }, [])

    const handleGenderChange = useCallback((event) => {
        setGender(event.target.value)
    }, [])

    const handleListItemPress = useCallback((id) => {
        setSelectedId(id)
    }, [])

    const handleAddKid = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleOpenAvatars = useCallback(() => {
        setAvatarsOpen(true)
    }, [])

    const handleCloseAvatars = useCallback(() => {
        setAvatarsOpen(false)
    }, [])

    const handleSelectAvatar = useCallback((newAvatar) => {
        setAvatar(newAvatar)
        setAvatarsOpen(false)
    }, [])

    const handleSave = useCallback(() => {
        if (!validForm) return
        return apiCreateKidProfile({ nickname, avatar, avatar_configuration: avatarConfiguration, gender, birthday: birthday.format('YYYY-MM-DD') })
            .then((kid) => apiAddRobloxProfile({ profile_id: kid.profile_id, roblox_kid_id: foundRobloxAccount.UserId, roblox_name: foundRobloxAccount.Name }))
            .then(() => {
                setOpen(false)
                setAvatar(`default-avatar-${Math.floor(Math.random() * 1000)}`)
                setNickname('')
                setBirthday(dayjs(new Date()))
                setGender('female')
                setRobloxName('')
                setKids(undefined)
            })
    }, [nickname, avatar, avatarConfiguration, gender, birthday, foundRobloxAccount, validForm])

    useEffect(() => {
        if (!token) return
        apiGetDefaultAvatarConfiguration({ avatar_id: avatar })
            .then(response => {
                setAvatarConfiguration(response.data.avatar_configuration)
            })
    }, [avatar, token])

    useEffect(() => {
        if (!selectedId || !kids) return
        const selected = kids.find(kid => kid.profile_id === selectedId)
        if (!selected) return
        dispatch(setKid(selected))
    }, [selectedId, kids, dispatch])

    useEffect(() => {
        if (!selectedId && kids && kids.length > 0) {
            setSelectedId(kids[0].profile_id)
        }
    }, [selectedId, kids])

    useEffect(() => {
        if (!token || kids) return
        apiGetKids()
            .then(response => setKids(response.data.profiles))
    }, [token, kids])

    useEffect(() => {
        if (Array.isArray(kids) && kids.length === 0) {
            handleAddKid()
        }
    }, [kids, handleAddKid])

    useEffect(() => {
        if (!token) return
        if (nickname.length < 3) {
            setNicknameAvailable(undefined)
            return
        }
        setNicknameAvailable(null)
        const timer = setTimeout(() => {
            apiCheckNicknameAvailability({ nickname })
                .then(available => setNicknameAvailable(available))
        }, 1500)
        return () => clearTimeout(timer)
    }, [nickname, token])

    useEffect(() => {
        if (robloxName.length >= MINIMUM_ROBLOX_NAME_LENGTH) {
            setNamesLoading(true)
            const timer = setTimeout(() => {
                apiCheckRobloxUsername({ username: robloxName })
                    .then(results => {
                        if (Array.isArray(results)) {
                            const found = results.find((entry) => entry.Name.toLowerCase() === robloxName.toLowerCase())
                            if (found) {
                                setFoundRobloxAccount(found)
                            } else {
                                setFoundRobloxAccount(false)
                            }
                            setNamesLoading(false)
                        } else {
                            console.error("Error in Roblox API", results)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }, 2000)
            return () => clearTimeout(timer)
        } else {
            setFoundRobloxAccount(null)
            setNamesLoading(false)
        }
    }, [robloxName])

    return (
        <StyledContainer>
            {kids &&
                <List component="nav">
                    {kids && kids.map((kid) => <KidItem key={kid.profile_id} id={kid.profile_id} selectedId={selectedId} kid={kid} onPress={handleListItemPress} />)}
                    <ListItemButton onClick={handleAddKid}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Add another child" />
                    </ListItemButton>
                </List>
            }
            {!kids &&
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
                    <CircularProgress color="primary" />
                </Box>
            }
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box style={modalStyle} display="flex" flexDirection="column" gap={3}>
                    <Typography variant="h2" textAlign="center">Add a child</Typography>

                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Box display="flex" flexShrink={1} position="relative">
                            <Avatar src={getAvatarURI(avatar)} alt='' sx={{ width: 80, height: 80 }} />
                            <Box display="flex" position="absolute" bottom={0} right={0}>
                                <IconButton aria-label="edit" size="small" sx={editAvatarStyle} onClick={handleOpenAvatars}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" gap={3}>
                        <Box display="flex" flexDirection="column" gap={1}>
                            <TextField
                                label="Username"
                                helperText="Don't use their real name."
                                value={nickname}
                                onChange={handleNicknameChange}
                            />
                            {nicknameAvailable === undefined &&
                                <Box display="flex" alignItems="center" gap={1}>
                                    <InfoIcon size={18} sx={{ color: "#C7C8CE" }} />
                                    <Typography variant="caption">Your username must be unique throughout Gabu.</Typography>
                                </Box>
                            }
                            {nicknameAvailable === null &&
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CircularProgress color="inherit" size={18} />
                                    <Typography variant="caption">Verifying availability...</Typography>
                                </Box>
                            }
                            {nicknameAvailable === true &&
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CheckCircleIcon size={18} sx={{ color: "#12B76A" }} />
                                    <Typography color="#39C463" variant="caption">Username available!</Typography>
                                </Box>
                            }
                            {nicknameAvailable === false &&
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CancelIcon size={18} sx={{ color: "#F23F43" }} />
                                    <Typography color="#C63C35" variant="caption">Username not available!</Typography>
                                </Box>
                            }
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date of Birth"
                                value={birthday}
                                onChange={handleBirthdayChange}
                                helperText="For age-appropriate games recommendations."
                            />
                        </LocalizationProvider>
                        <FormControl fullWidth>
                            <InputLabel>Gender</InputLabel>
                            <Select
                                value={gender}
                                label="Gender"
                                onChange={handleGenderChange}
                            >
                                <MenuItem value='female'>Female</MenuItem>
                                <MenuItem value='male'>Male</MenuItem>
                            </Select>
                        </FormControl>
                        <Box display="flex" flexDirection="column" gap={1}>
                            <TextField
                                label="Roblox Username"
                                helperText="You can provide it afterwards."
                                value={robloxName}
                                onChange={handleRobloxNameChange}
                            />
                            {namesLoading &&
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CircularProgress color="inherit" size={18} />
                                    <Typography variant="caption">Verifying</Typography>
                                </Box>
                            }
                            {!namesLoading && foundRobloxAccount === null &&
                                <Box display="flex" alignItems="center" gap={1}>
                                    <InfoIcon size={18} sx={{ color: "#C7C8CE" }} />
                                    <Typography variant="caption">Note: The username must be of at least 6 characters.</Typography>
                                </Box>
                            }
                            {!namesLoading && foundRobloxAccount === false &&
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CancelIcon size={18} sx={{ color: "#F23F43" }} />
                                    <Typography variant="caption" color="#C63C35">Roblox account not found</Typography>
                                </Box>
                            }
                            {!namesLoading && foundRobloxAccount &&
                                <Box display="flex" alignItems="center" gap={1}>
                                    <CheckCircleIcon size={18} sx={{ color: "#12B76A" }} />
                                    <Typography variant="caption" color="#39C463">{foundRobloxAccount.DisplayName} (Account ID: {foundRobloxAccount.UserId})</Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box marginTop={3} display="flex" flexDirection="column" gap={2}>
                        <Button variant="contained" sx={{ width: '100%' }} disabled={!validForm} onClick={handleSave}>Continue</Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={avatarsOpen}
                onClose={handleCloseAvatars}
            >
                <Box style={avatarsStyle}>
                    <Typography variant="h3">Select an avatar</Typography>
                    <Box display="grid" gridTemplateColumns="repeat(10, 58px)">
                        {Array.from({ length: 100 }, (_, x) => {
                            return <AvatarButton key={x} index={x} onClick={handleSelectAvatar} />
                        })}
                    </Box>
                </Box>
            </Modal>
        </StyledContainer>
    )
}

const KidItem = ({ id, selectedId, kid, onPress }) => {
    const handlePress = useCallback(() => {
        if (onPress) onPress(id)
    }, [id, onPress])

    const avatar = useMemo(() => {
        return getAvatarURI(kid.avatar)
    }, [kid])

    return (
        <ListItemButton
            selected={selectedId === id}
            onClick={handlePress}
        >
            <ListItemIcon>
                <Avatar sx={{ width: 40, height: 40 }} src={avatar} />
            </ListItemIcon>
            <ListItemText primary={kid.nickname} />
        </ListItemButton>
    )
}

const AvatarButton = ({ index, onClick }) => {
    const avatar = useMemo(() => `default-avatar-${index}`, [index])

    const handleClick = useCallback(() => {
        if (onClick) onClick(avatar)
    }, [avatar, onClick])

    return (
        <Button onClick={handleClick}>
            <Avatar src={getAvatarURI(avatar)} alt='' sx={{ width: 40, height: 40 }} />
        </Button>
    )
}

export default KidList