import { Typography } from "@mui/material"

export const getAvatarURI = (avatar) => `https://assets.playgabu.com/avatars/${avatar}.png`

export const processText = (text) => {
    const parts = text.split('**')
    const components = parts.reduce(([components, bold], part, index) => {
        if (bold) components.push(<Typography variant="body1" fontWeight="600" component="span" key={index}>{part}</Typography>)
        else components.push(<Typography variant="body1" component="span" key={index}>{part}</Typography>)
        return [components, !bold]
    }, [[], false])
    return components[0]
}

export const formatTime = (time) => {
    if (time < 60) return `${Math.round(time)} minutes`
    const hours = Math.round(time * 10 / 60) / 10
    return `${hours} hours`
}
